:root {
  --primary: #03045e;
  --secondary: #0077b6;
  --tertiary: #00b4d8;
  --background: #90e0ef;
  --background-dark: #79bbd7;
  --background-light: #caf0f8;
  --accent: #7400b8;
}

.primary {
  color: var(--primary);
}

.accent {
  color: var(--accent);
}

body {
  background-color: var(--background);
  color: var(--primary);
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 14px;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--background-dark);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--primary);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--primary);
}

.app {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 2rem);
}

.app-title {
  display: flex;
  margin-left: -7px;
  margin-right: -7px;
  margin-block-start: 0em;
  margin-block-end: 0.5em;
  padding: 0.5rem 0.5rem 0.75rem 0.5rem;
  border-bottom: 1px solid var(--tertiary);
  font-size: 1.5rem;
  align-items: center;
}

.app-title-icon {
  margin: 5px 0.5rem;
  font-size: 2rem;
}

.app-navigation {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  min-height: calc(100vh - 116px);
}

.app-navigation-items {
  margin-left: -7px;
}

.app-navigation-item {
  padding: 1rem 0.5rem;
  margin-bottom: 5px;
  margin-left: -1px;
  font-weight: bold;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  text-decoration: none;
  display: block;
  color: var(--primary);
  border: 1px solid var(--primary);
  border-left: none;
}

.app-navigation-icon {
  font-size: large;
  margin-bottom: -3px;
}

.app-navigation-item.selected {
  background: var(--primary);
  color: var(--background-light);
}

.app-navigation-content {
  flex-basis: calc(100% - 140px);
  display: flex;
  justify-content: center;
  padding: 0 0.5rem 0 1rem;
}

.app-footer {
  text-align: center;
  margin: 3px;
}

.product-list,
.sale-list {
  max-height: calc(100vh - 125px);
  overflow-y: auto;
}

.product-table,
.sale-table {
  width: 100%;
  border: 1px solid var(--primary);
  border-collapse: separate;
  border-left: 0;
  border-radius: 10px;
  border-spacing: 0px;
  overflow: hidden;
}

.product-table th,
.sale-table th {
  background: var(--primary);
  color: var(--background-light);
  padding-bottom: 5px;
  padding-top: 5px;
}

.product-table td,
.sale-table td {
  max-width: 250px;
  min-width: 80px;
  border-top: 1px solid var(--primary);
}

table.with-detail > tbody > tr > th:first-child,
table.with-detail > tbody > tr > td:first-child {
  max-width: 20px;
  min-width: 20px;
}

.product-table .odd td,
.sale-table .odd td {
  background: var(--background-dark);
}

.product-table td,
.product-table th,
.sale-table td,
.sale-table th {
  padding-left: 10px;
  padding-right: 10px;
  border-left: 1px solid var(--primary);
}

thead:first-child tr:first-child th:first-child,
tbody:first-child tr:first-child td:first-child {
  border-radius: 10px 0 0 0;
}

thead:last-child tr:last-child th:first-child,
tbody:last-child tr:last-child td:first-child {
  border-radius: 0 0 0 10px;
}

tbody:last-child tr:last-child td:last-child {
  border-radius: 0 0 10px 0px;
}

.start {
  text-align: start;
}

.center {
  text-align: center;
}

.width-100 {
  width: 100%;
}

td.padding-2 {
  padding: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.end {
  text-align: end;
}

.btn {
  background: var(--primary);
  border: 1px solid var(--primary);
  outline: none;
  border-radius: 5px;
  color: var(--background-light);
  cursor: pointer;
  margin: 2px;
  width: 75px;
}

.btn:disabled {
  background: var(--background-dark);
  border: 1px solid var(--primary);
  color: var(--primary);
  cursor: default;
}

.btn.btn-accent,
.btn-tiny.btn-accent {
  background: var(--accent);
  border-color: var(--accent);
  color: var(--background-light);
}

.product-form,
.sale-form {
  display: flex;
  flex-direction: column;
  height: fit-content;
  padding: 0.5rem 1.5rem;
  border: 1px solid var(--primary);
  border-radius: 10px;
}

.product-form .form-field,
.sale-form .form-field {
  padding-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.product-form .form-field label,
.sale-form .form-field label {
  display: inline-block;
  min-width: 150px;
  font-weight: 400;
}

.product-form .form-field input,
.sale-form .form-field input {
  display: inline-block;
  min-width: 200px;
  height: 2rem;
  border-radius: 10px;
  border-color: transparent;
  padding-left: 5px;
  padding-right: 5px;
  outline: none;
}

.sale-form .form-field input.tiny {
  min-width: 40px;
  max-width: 40px;
  margin-left: 5px;
}

.sale-form .form-field input.medium {
  display: inline-block;
  min-width: 120px;
  max-width: 120px;
  margin-left: 5px;
}

.product-form .form-field input[type='checkbox'],
.sale-form .form-field input[type='checkbox'] {
  min-width: 20px;
  padding-left: 0px;
  padding-right: 0px;
}

.product-form .product-form-footer,
.sale-form .sale-form-footer {
  text-align: end;
  padding-bottom: 5px;
}

.form-field .select {
  align-self: normal;
  width: 100%;
}

.form-field .select input {
  max-height: 1.5rem;
}

.form-group {
  border: 1px solid var(--primary);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  padding: 1rem;
}

button.btn-tiny {
  border: 1px solid var(--primary);
  background-color: var(--primary);
  color: var(--background-light);
  font-weight: 500;
  margin-left: 5px;
  border-radius: 10px;
}

.btn-icon {
  font-size: 1.2rem;
  margin-bottom: -3px;
  cursor: pointer;
}

.rotate {
  transform: rotate(45deg);
}

.form-group-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 0.5rem;
}

.form-group-header .form-title {
  font-weight: bold;
}


.primary-spinner {
  display: inline-block;
  padding: 1rem;
}

.primary-spinner * {
  fill: var(--primary);
}